body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(233, 240, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
}

.menu-content {
  /* display: none !important; */
  opacity: 0;
}

.api-content {
  width: 100% !important;
  margin-left: -260px;
}

.fc-widget-small {
  bottom: 25px!important;
}

@keyframes move {
  0% {
    transform: translate3d(0,0,0);
  }

  25% {
    transform: translate3d(-3%,-2%,0);
  }

  75% {
    transform: translate3d(-2%,-3%,0);
  }

  100% {
    transform: translate3d(0,0,0);
  }
}

.background-animation {
  animation: move 10s infinite ease-in-out;
}

#auth-box {
  background-color: white;
  padding: 40px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 400px) {
  #auth-grid {
    background-color: white;
    padding: 0px;
  }
  #auth-box {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    height: 320px;
  }
  #about-list {
    background-color: white;
    margin-top: 300px;
  }
}

.text-bg-red {
  margin-left: 20px;
  position: relative;
  display: inline-block;
  --text-opacity: 1;
}

.text-bg-red:before {
  width: calc(100% + .5rem);
  height: 1.3em;
  content: "";
  display: block;
  position: absolute;
  left: -.25rem;
  bottom: -.15rem;
  background: linear-gradient(90deg,rgba(0, 89, 255, 0.15),rgba(0, 38, 255, 0.05));
}
